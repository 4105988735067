import { createSettingsParam, SettingsParamType } from '@wix/tpa-settings';

import { SECRET_SLUG } from 'api/groups/constants';

import * as common from 'settings/common';
import * as feed from 'settings/feed';

export default {
  ...common.settings,
  ...feed.settings,
  postsCount: createSettingsParam('postsCount', {
    getDefaultValue: () => 5,
    type: SettingsParamType.Number,
  }),

  showComments: createSettingsParam('showComments', {
    getDefaultValue: () => true,
    type: SettingsParamType.Boolean,
  }),

  groupId: createSettingsParam('groupId', {
    getDefaultValue: () => SECRET_SLUG,
    type: SettingsParamType.Text,
  }),
};
